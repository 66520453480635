import React, { useContext } from 'react';
import {PadContext} from '../../context/Context';
// import MasterContext from '../../context/MasterContext';
// import Users from './Users';
// import Tenants from './Tenants';
// import Home from './Home';
// import Invoices from './Invoices';
// import Products from './Products';
// import Settings from './Settings';
// import Payments from './Clientes';
import Painel from './Painel';
import Settings from './Settings';
import Cadastros from './Cadastros';

export default function RenderSection() {
  const { section } = useContext(PadContext);

  switch (section) {
    case 'cadastros':
      return <Cadastros />;

    case 'settings':
      return <Settings />;

    default:
      return <Painel />;
  }
}
