import { useContext, useEffect, useState } from 'react';
import NavBar from '../components/NavBar';
import LoadingFull from '../components/LoadingFull';
import {GlobalContext} from '../context/Context';
import { data_br } from '../assets/js/helpers';
import CONF from '../config';
import PackageContext from '../packages/wookie/context/PackageContext';
import ContainerBox from '../packages/wookie/box/ContainerBox';

import { IconsUI, ui } from '../packages/wookie/icons';

export default function Portal() {
  const { images } = CONF;
  const { checkLogin, loading, navigate, store, setProduto} = useContext(GlobalContext);
  const { theme } = useContext(PackageContext);
  const [assinaturas, setAssinaturas] = useState([]);
  // const { assinantes } = store();

  // console.log(newObj);

  useEffect(() => {
    setProduto('global');
    const logged = checkLogin();
    if (!logged) navigate('/login');

    const { assinantes } = store();
    // console.log(assinantes);

    const newObj = assinantes.map((assinante) => {
      const tenant = {
        codigo: assinante.TENANT_CODIGO,
        active: assinante.TENANT_ACTIVE,
        name: assinante.TENANT_NAME,
        tenant: assinante.TENANT_TENANT,
        telefone: assinante.TENANT_TELEFONE,
        endereco: assinante.TENANT_ENDERECO,
        cidade: assinante.TENANT_CIDADE_UF,
        email: assinante.TENANT_EMAIL,
      };
      const subscriber = {
        codigo: assinante.ASSINANTE_CODIGO,
        active: assinante.ASSINANTE_ACTIVE,
        expiration: assinante.EXPIRATION,
        support: assinante.SUPPORT,
        updates: assinante.UPDATES,
        created_at: assinante.CREATED_AT,
        updated_at: assinante.UPDATED_AT,
        plano_id: assinante.PLANO_ID,
        plano_nome: assinante.PLANO_NOME,
        plano_valor: assinante.PLANO_VALOR,
        plano_trial: assinante.PLANO_TRIAL,
        plano_rota: assinante.PLANO_ROTA,
        plano_max_users: assinante.PLANO_MAX_USERS,
        plano_active: assinante.PLANO_ACTIVE,
        product_nome: assinante.PRODUCT_NOME,
        product_descricao: assinante.PRODUCT_DESCRICAO,
        product_global: assinante.PRODUCT_GLOBAL,
        product_paywall: assinante.PRODUCT_PAYWALL,
        product_active: assinante.PRODUCT_ACTIVE,
        product_codigo: assinante.PRODUCT_CODIGO,
        product_id: assinante.PRODUCT_ID,
        product_service: assinante.PRODUCT_SERVICE,
        frequencia_id: assinante.FREQUENCIA_ID,
        frequencia_nome: assinante.FREQUENCIA_NOME,
        frequencia_meses: assinante.FREQUENCIA_MESES,
        frequencia_codigo: assinante.FREQUENCIA_CODIGO,
        user_cpf: assinante.USER_CPF,
        user_nome: assinante.USER_NOME,
        user_email: assinante.USER_EMAIL,
        user_telefone: assinante.USER_TELEFONE,
        user_active: assinante.USER_ACTIVE,
        user_codigo: assinante.USER_CODIGO,
        role: assinante.ROLE,
        role_description: assinante.ROLE_DESCRIPTION,
        role_codigo: assinante.ROLE_CODIGO,
        tenant_expiration: assinante.TENANTS_USERS_EXPIRATION,
        tenant_active: assinante.TENANTS_USERS_ACTIVE,
        tenant_codigo: assinante.TENANTS_USERS_CODIGO,
      };

      return {
        tenant,
        subscriber,
      };
    });

    // i need to add a property to the tenant and another property to the subscriber

    const organized = newObj.reduce((acc, curr) => {
      const { tenant, subscriber } = curr;
      if (!acc[tenant.tenant]) {
        acc[tenant.tenant] = {
          tenant,
          assinaturas: [subscriber],
        };
      } else {
        acc[tenant.tenant].assinaturas.push(subscriber);
      }
      return acc;
    }, {});

    // console.log(organized);

    setAssinaturas(organized);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <LoadingFull />;

  return (
    <ContainerBox
    navbar={<NavBar />}
    main={
      <main className='container gocol'>
      <div className='d-flex flex-row w100'>
        <section className='main-container-render'>
          <p>Meus serviços</p>

          {Object.keys(assinaturas).length > 0 ? (
            Object.keys(assinaturas).map((key) => {
              return (
                <section key={key}>
                  <h3>{key}</h3>
                  <div>
                    {assinaturas[key].tenant.name}
                    <br />
                    {assinaturas[key].tenant.email}
                    {' | '} {assinaturas[key].tenant.telefone}
                    <br />
                    {assinaturas[key].tenant.endereco},{' '}
                    {assinaturas[key].tenant.cidade}
                  </div>
                  <div className='col gy-4 pt-2 pb-3'>
                    {assinaturas[key].assinaturas.length > 0 ? (
                      assinaturas[key].assinaturas.map((assinatura) => {
                        return (
                          <div
                            key={assinatura.codigo}
                            className='d-flex justify-content-start py-3'
                          >
                            <div className='box-product'>
                              <div className='p-2'>
                                <h5>{assinatura.product_nome}</h5>

                                <img
                                  src={images[theme][assinatura.product_service]}
                                  className='img-logo-product'
                                  alt=''
                                />
                              </div>
                              {assinatura.plano_rota ? (
                                <a
                                  href={`/${assinatura.plano_rota}/${key}`}
                                  className='d-flex flex-row align-items-center mx-3 hov-green hov-pointer wp-70'
                                >
                                  <IconsUI
                                    info={ui.action.login}
                                    clsnm='svg14'
                                  />
                                  <span className='ms-2'>Abrir</span>
                                </a>
                              ) : (
                                <span className='d-flex flex-row align-items-center mx-3 wp-70 fc-grey'>
                                  <span className='ms-2'>Desktop</span>
                                </span>
                              )}

                              <div className='p-2 ms-3'>
                                <p className='fw500 mb-0'>
                                  {assinatura.plano_nome}
                                </p>
                                <p className='mt-0'>
                                  {assinatura.product_descricao}
                                  <br />
                                  Expira em {data_br(assinatura.expiration)}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div>
                        <p>Não há assinaturas ativas</p>
                      </div>
                    )}
                  </div>

                  <div></div>
                </section>
              );
            })
          ) : (
            <div>
              <p>Não há contas ativas</p>
            </div>
          )}
        </section>
      </div>
      <section className='py-3'>
        <span className='hov-green hov-pointer'>
          {' '}
          <IconsUI info={ui.money.cart} />
          <span className='ms-2'>Adicionar assinatura (em breve)</span>
        </span>
      </section>
    </main>
    }
   
    />
  );
}
