import { Routes, Route } from 'react-router-dom';

import Login from '../pages/Auth/Login';
import Logout from '../pages/Auth/Logout';
import Register from '../pages/Auth/Register';
import Recover from '../pages/Auth/Recover';
import Reset from '../pages/Auth/Reset';
import ConfirmEmail from '../pages/Auth/ConfirmEmail';

import Page404 from '../pages/Handlers/Page404';
import Page500 from '../pages/Handlers/Page500';
import Juris from '../products/juris/Juris';

import User from '../pages/User';
import Tenant from '../pages/Tenant';
import Portal from '../pages/Portal';

import Home from '../pages/Home';

import Wallet from '../pages/Money/Wallet';
// import MPWallet from '../pages/Meli/MPWallet';

import Admin from '../pages/Admin';
import ProtectedRoute from './ProtectedRoute';

import Privacidade from '../pages/Legal/Privacidade';
import Termos from '../pages/Legal/Termos';

import Pad from '../products/pad/Pad';

// import IconsExample from '../dump/IconsExample';

export default function AppRoutes() {
  return (
    <Routes>
      {/* <Route
        path='/icons'
        element={<IconsExample />}
      /> */}

      <Route
        path='/admin'
        element={
          <ProtectedRoute>
            <Admin />
          </ProtectedRoute>
        }
      />

      <Route
        path='/wallet'
        element={
          <ProtectedRoute>
            <Wallet />
          </ProtectedRoute>
        }
      />

      <Route
        path='/wallet/:subscription'
        element={
          <ProtectedRoute>
            <Wallet />
          </ProtectedRoute>
        }
      />

      <Route
        path='/pad'
        element={<Pad />}
      />

      <Route
        path='/portal'
        element={
          <ProtectedRoute>
            <Portal />
          </ProtectedRoute>
        }
      />

      <Route
        path='/juris/:tenant'
        element={
          <ProtectedRoute>
            <Juris />
          </ProtectedRoute>
        }
      />

      <Route
        path='juris'
        element={
          <ProtectedRoute>
            <Juris />
          </ProtectedRoute>
        }
      />

      <Route
        path='user'
        element={
          <ProtectedRoute>
            <User />
          </ProtectedRoute>
        }
      />

      <Route
        path='/conta/:tenant'
        element={
          <ProtectedRoute>
            <Tenant />
          </ProtectedRoute>
        }
      />

      <Route
        path='/login'
        element={<Login />}
      />
      
      <Route
        path='/confirmar/:codigo'
        element={<ConfirmEmail />}
      />

      <Route
        path='/logout'
        element={<Logout />}
      />
      <Route
        path='/register'
        element={<Register />}
      />
      <Route
        path='/recover'
        element={<Recover />}
      />
      <Route
        path='/reset'
        element={<Reset />}
      />
      <Route
        path='/500'
        element={<Page500 />}
      />

      <Route
        path='/privacidade'
        element={<Privacidade />}
      />
      <Route
        path='/termos'
        element={<Termos />}
      />

      <Route
        exact
        path='/'
        element={<Home />}
      />
      <Route
        path='*'
        element={<Page404 />}
      />
    </Routes>
  );
}
