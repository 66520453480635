import React, { useContext } from 'react';
// import {GlobalContext} from '../../context/Context';
import '../../assets/css/app/sidebar.css';
import '../../assets/css/components/navbar.css';
// import CONF from '../../config';
import { PadContext } from '../../context/Context';
import { IconsUI, ui } from '../../packages/wookie/icons';
import StorageServices from '../storage/StorageServices';

export default function SideBar() {
  const { setSection } = useContext(PadContext);
  // const { navigate, logout } = useContext(GlobalContext);

  // const handleLogout = () => {
  //   logout();
  //   navigate('/login');
  // };

  return (
    <div className='container-sidebar p-2'>
      <button
        className='sideMenuButton'
        onClick={() => {
          const labels = document.querySelectorAll('.sp-label-sidebar');
          labels.forEach((label) => {
            label.classList.toggle('divHide');
          });
        }}
      >
        <IconsUI
          info={ui.misc.menu}
          clsnm='svg14 wp35'
        />
        <span className='ms-2 sp-label-sidebar'>Menu</span>
      </button>

      <button
        className='sideMenuButton'
        onClick={() => setSection('home')}
      >
        <IconsUI
          info={ui.misc.house}
          clsnm='svg14 wp35'
        />
        <span className='ms-2 sp-label-sidebar'>Home</span>
      </button>

      <button
        className='sideMenuButton'
        onClick={() => setSection('painel')}
      >
        <IconsUI
          info={ui.misc.dash}
          clsnm='svg14 wp35'
        />
        <span className='ms-2 sp-label-sidebar'>Painel</span>
      </button>

      <button
        className='sideMenuButton'
        onClick={() => setSection('cadastros')}
      >
        <IconsUI
          info={ui.user.reg}
          clsnm='svg14 wp35'
        />
        <span className='ms-2 sp-label-sidebar'>Cadastros</span>
      </button>

      <button
        className='sideMenuButton'
        onClick={() => setSection('contratos')}
      >
        <IconsUI
          info={ui.misc.products}
          clsnm='svg14 wp35'
        />
        <span className='ms-2 sp-label-sidebar'>Contratos</span>
      </button>

      <button
        className='sideMenuButton'
        onClick={() => setSection('processos')}
      >
        <IconsUI
          info={ui.misc.starlight}
          clsnm='svg14 wp35'
        />
        <span className='ms-2 sp-label-sidebar'>Processos</span>
      </button>

      <button
        className='sideMenuButton'
        onClick={() => setSection('financeiro')}
      >
        <IconsUI
          info={ui.money.reg}
          clsnm='svg14 wp35'
        />
        <span className='ms-2 sp-label-sidebar'>Financeiro</span>
      </button>

      {/* <IconsUI
        info={ui.money.invoice}
        click={() => setSection('faturas')}
        clsnm='svg14 wp35'
        sideText='Faturas'
        sideTextClass='ms-2 sp-label-sidebar'
        sideDivClass='my-2 hovYellow mousePointerHover'
      /> */}

      <div className='sp-label-sidebar'>
        <hr />
        {/* <span className='fs08 textContrastLow sp-label-sidebar'>+ módulos</span>
      <span className='d-block my-4'>
        <img
          src={CONF.images[theme].signer}
          alt='signer'
          className='wpMax100'
        />
      </span>
      <span className='d-block my-4'>
        <img
          src={CONF.images[theme].calc}
          alt='calc'
          className='wpMax100'
        />
      </span> */}
        <StorageServices />
      </div>
      <hr />

      <button
        className='sideMenuButton'
        onClick={() => setSection('settings')}
      >
        <IconsUI
          info={ui.misc.config}
          clsnm='svg14 wp35'
        />
        <span className='ms-2  fs09 sp-label-sidebar'>Configurações</span>
      </button>
    </div>
  );
}
