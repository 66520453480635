import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { IMaskInput } from 'react-imask';
import { reset } from '../../services/auth';
import Loading from '../../components/Loading';

import CONF from '../../config';
import Wrapper from '../../packages/wookie/misc/Wrapper';
import PackageContext from '../../packages/wookie/context/PackageContext';

export default function Reset() {
  const [cpf, setCpf] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { theme } = useContext(PackageContext);
  const reseter = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await reset(email, cpf, password, otp);
      const data = response.data;
      console.log(data);
      if (data.success) {
        setMessage('Você pode fazer login com a nova senha.');
      } else {
        setMessage(data.message);
      }
    } catch (error) {
      setMessage(error.response.data.message);
    }

    setLoading(false);
  };

  return (
    <Wrapper
      conf={CONF}
      text={
        <Form
          data-bs-theme={theme}
          className='mt-3 wpMin250'
        >
          <p className='mt-5 fs12'>Crie uma nova senha</p>
          <Form.Group className='mb-3'>
            <Form.Label
              htmlFor='email'
              className='fs10 textContrastLow faLeft m-0 w100'
            >
              E-mail
            </Form.Label>
            <Form.Control
              required
              type='text'
              id='email'
              name='email'
              autoComplete='email'
              aria-describedby='email'
              onChange={({ target: { value } }) => setEmail(value)}
              className='mb-2'
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label
              htmlFor='otp'
              className='fs10 textContrastLow faLeft m-0 w100'
            >
              OTP
            </Form.Label>
            <Form.Control
              required
              type='text'
              id='otp'
              name='otp'
              autoComplete='otp'
              aria-describedby='otp'
              onChange={({ target: { value } }) => setOtp(value)}
              className='mb-2'
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label
              htmlFor='cpf'
              className='fs10 textContrastLow faLeft m-0 w100'
            >
              CPF
            </Form.Label>
            <Form.Control
              required
              type='text'
              as={IMaskInput}
              mask='000.000.000-00'
              id='cpf'
              name='cpf'
              autoComplete='cpf'
              aria-describedby='cpf mask-cpf'
              onChange={({ target: { value } }) => setCpf(value)}
              className='mb-2'
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label
              htmlFor='password'
              className='fs10 textContrastLow faLeft m-0 w100'
            >
              Nova Senha
            </Form.Label>
            <Form.Control
              required
              type='password'
              id='password'
              className='mb-4'
              onChange={({ target: { value } }) => setPassword(value)}
            />
          </Form.Group>
          <p className='my-5 text-center fc-red fw-500'>{message}</p>

          {loading ? (
            <Loading class_op='mt-5' />
          ) : (
            <div className='d-flex flex-row justify-content-around my-2'>
              <Link to='/login'>
                <Button
                  className='no_under'
                  variant='link'
                >
                  Já tem cadastro? Faça login
                </Button>
              </Link>
              <Button
                variant='primary'
                onClick={(event) => reseter(event)}
              >
                Trocar a senha
              </Button>
            </div>
          )}
        </Form>
      }
    />
  );
}
