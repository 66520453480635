import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { IMaskInput } from 'react-imask';
import { recover } from '../../services/auth';
import Loading from '../../components/Loading';

import CONF from '../../config';
import Wrapper from '../../packages/wookie/misc/Wrapper';
import PackageContext from '../../packages/wookie/context/PackageContext';

export default function Recover() {
  const [cpf, setCpf] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const { theme } = useContext(PackageContext);

  const recovery = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const response = await recover(email, cpf);

      console.log(response);
      const data = response.data;
      setMessage(data.message);
    } catch (error) {
      setMessage(error.response.data.message);
    }

    setLoading(false);
  };

  return (
    <Wrapper
      conf={CONF}
      text={
        <Form
          data-bs-theme={theme}
          className='mt-3 wpMin300'
        >

        <p className='mt-5 fs12'>Recupere seu acesso</p>

          <Form.Group className='mb-3'>
            <Form.Label
              htmlFor='cpf'
              className='fs10 textContrastLow faLeft m-0 w100'
            >
              CPF
            </Form.Label>
            <Form.Control
              required
              type='text'
              as={IMaskInput}
              mask='000.000.000-00'
              id='cpf'
              name='cpf'
              autoComplete='cpf'
              aria-describedby='cpf mask-cpf'
              onChange={({ target: { value } }) => setCpf(value)}
              className='mb-2'
            />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label
              htmlFor='email'
              className='fs10 textContrastLow faLeft m-0 w100'
            >
              E-Mail
            </Form.Label>
            <Form.Control
              required
              type='text'
              id='email'
              name='email'
              autoComplete='email'
              aria-describedby='email'
              onChange={({ target: { value } }) => setEmail(value)}
              className='mb-2'
            />
          </Form.Group>

          <p className='my-5 faCenter cRed fw500'>{message}</p>
          <p className='my-5 faCenter cRed fw500'>{message}</p>

          {loading ? (
            <div className='d-flex flex-row justify-content-center'>
              <Loading class_op='mt-5' />
            </div>
          ) : (
            <div className='d-flex flex-column align-items-center  justify-content-around my-2'>
              <Button
                className='mb-2'
                variant='primary'
                onClick={(event) => recovery(event)}
              >
                Solicitar código de recuperação
              </Button>
              <Link to='/reset'>
                <Button
                  className='no_under'
                  variant='link'
                >
                  Já tem o código OTP? Clique aqui.
                </Button>
              </Link>
            </div>
          )}
        </Form>
      }
    />
  );
}
