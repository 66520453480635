import React, { useContext, useState } from 'react';
import logo from '../../assets/img/logo.png';

import CONF from '../../config';
import Wrapper from '../../packages/wookie/misc/Wrapper';
import { Navigate, useParams } from 'react-router-dom';
import PackageContext from '../../packages/wookie/context/PackageContext';
import { Form, Button } from 'react-bootstrap';
import { IMaskInput } from 'react-imask';
import Loading from '../../components/Loading';
import { confirmer } from '../../services/auth';

export default function ConfirmEmail() {
  const { codigo } = useParams();
  const { theme } = useContext(PackageContext);
  const [cpf, setCpf] = useState('');
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  async function sendConfirmation(event) {
    event.preventDefault();
    setLoading(true);
    const response = await confirmer(email, cpf, otp, codigo);
    if (response.status === 200) {
      setMessage('Conta ativada com sucesso!');
      setTimeout(() => {
        Navigate('/login');
      }, 3000);
    } else {
      setMessage(response.data.message);
    }
    setLoading(false);
  }

  return (
    <Wrapper
      conf={CONF}
      showLogo={false}
      text={
        <div className=' '>
          <a href='/'>
            <img
              src={logo}
              className='w70 wpMax250 my-5'
              alt='logo'
            />
          </a>
          <p className='fs12 my-3 pt-3'>
            Confirme seus dados e ative sua conta
          </p>
          <div className='my-3'>
            <Form
              data-bs-theme={theme}
              className='mt-3 w90 wpMax400'
            >
              <Form.Group className='mb-3'>
                <Form.Label
                  htmlFor='email'
                  className='fs10 textContrastLow faLeft m-0 w100'
                >
                  E-mail
                </Form.Label>
                <Form.Control
                  required
                  type='text'
                  id='email'
                  name='email'
                  autoComplete='email'
                  aria-describedby='email'
                  onChange={({ target: { value } }) => setEmail(value)}
                  className='mb-2'
                />
              </Form.Group>

              <Form.Group className='mb-3'>
                <Form.Label
                  htmlFor='cpf'
                  className='fs10 textContrastLow faLeft m-0 w100'
                >
                  CPF
                </Form.Label>
                <Form.Control
                  required
                  type='text'
                  as={IMaskInput}
                  mask='000.000.000-00'
                  id='cpf'
                  name='cpf'
                  autoComplete='cpf'
                  aria-describedby='cpf mask-cpf'
                  onChange={({ target: { value } }) => setCpf(value)}
                  className='mb-2'
                />
              </Form.Group>

              <div className='d-flex flex-row justify-content-center align-items-center my-2'>
                <Form.Group className='mb-3 faCenter wp175'>
                  <Form.Label
                    htmlFor='otp'
                    className='fs10 textContrastLow faCenter m-0 w100'
                  >
                    OTP
                  </Form.Label>
                  <Form.Control
                    required
                    type='text'
                    size='lg'
                    id='otp'
                    name='otp'
                    minLength={6}
                    maxLength={6}
                    autoComplete='otp'
                    aria-describedby='otp'
                    onChange={({ target: { value } }) => setOtp(value)}
                    className='m-0 ls06 faCenter'
                  />
                </Form.Group>
              </div>

              <p className='my-5 text-center fc-red fw-500'>{message}</p>

              {loading ? (
                <Loading class_op='mt-5' />
              ) : (
                <div className='d-flex flex-column justify-content-around my-2'>
                  <p>
                    Confirmo que os dados fornecidos são meus e que estou de
                    acordo com os{' '}
                    <a
                      href='/termos'
                      target='_blank'
                      rel='noreferrer'
                      className='noUnder'
                    >
                      Termos de Uso
                    </a>{' '}
                    e{' '}
                    <a
                      href='/privacidade'
                      target='_blank'
                      rel='noreferrer'
                      className='noUnder'
                    >
                      Política de Privacidade
                    </a>
                    .
                  </p>

                  <Button
                    variant='primary'
                    onClick={(event) => sendConfirmation(event)}
                  >
                    Confirmar e ativar minha conta
                  </Button>

                  <Button
                    className='noUnder my-3'
                    variant='link'
                    onClick={() => Navigate('/login')}
                  >
                    Já tem cadastro? Faça login
                  </Button>
                </div>
              )}
            </Form>
          </div>
        </div>
      }
    />
  );
}
