import React, { useState, useContext, useEffect } from 'react';
import NavBar from '../components/NavBar';
import LoadingFull from '../components/LoadingFull';
import { GlobalContext } from '../context/Context';
import { fileIcon } from '../components/IconsUI';
import { useParams } from 'react-router-dom';
import { formatCapitalizeFirst } from '../assets/js/helpers';
import * as end from '../services/endpoints';
import * as api from '../services/requests';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { IMaskInput } from 'react-imask';
import BlobViewer from '../components/BlobViewer';

import PackageContext from '../packages/wookie/context/PackageContext';
import ContainerBox from '../packages/wookie/box/ContainerBox';

import StorageServices from '../components/storage/StorageServices';
// import GoogleDrive from '../components/storage/GoogleDrive';

export default function Tenant() {
  const { navigate, loading, checkLogin, store, setProduto } = useContext(GlobalContext);
  const { theme } = useContext(PackageContext);
  const { user, assinantes, tenantsUsers } = store();

  const { tenant } = useParams();
  const [subscribers, setSubscribers] = useState(tenantsUsers);
  const [files, setFiles] = useState(null);

  const infoTenant =
    subscribers &&
    subscribers.find((assinante) => assinante.TENANT_TENANT === tenant);

  const {
    TENANT_NAME,
    TENANT_EMAIL,
    TENANT_CIDADE_UF,
    TENANT_ENDERECO,
    TENANT_TELEFONE,
    TENANT_CODIGO,
    TENANT_TENANT,
  } = infoTenant;

  const [status, setStatus] = useState({
    name: TENANT_NAME,
    email: TENANT_EMAIL,
    telefone: TENANT_TELEFONE,
    endereco: TENANT_ENDERECO,
    numero: 'S/N',
    cidadeUF: TENANT_CIDADE_UF,
    tenant_codigo: TENANT_CODIGO,
    tenant: TENANT_TENANT,
  });

  async function getCEP(e) {
    e.preventDefault();
    let sanitizedCEP = e.target.value.replaceAll('-', '');
    const res = await fetch(`https://viacep.com.br/ws/${sanitizedCEP}/json/`);
    const data = await res.json();
    if (data.erro) {
      alert('CEP não encontrado');
    }
    setStatus({
      ...status,
      data,
      cidadeUF: `${formatCapitalizeFirst(data.localidade)}-${data.uf}`,
      endereco: `${formatCapitalizeFirst(data.logradouro)}, n° ${
        status.numero
      }, bairro ${formatCapitalizeFirst(data.bairro)}, CEP ${
        data.cep
      }, ${formatCapitalizeFirst(data.localidade)}-${data.uf}`,
    });
  }

  function editEndereco(num) {
    const { data } = status;
    const enderecoEdit = `${formatCapitalizeFirst(
      data.logradouro,
    )}, n° ${num}, bairro ${formatCapitalizeFirst(data.bairro)}, CEP ${
      data.cep
    }, ${formatCapitalizeFirst(data.localidade)}-${data.uf}`;
    console.log(enderecoEdit);
    setStatus({ ...status, endereco: enderecoEdit, numero: num });
  }

  const logged = checkLogin();

  useEffect(() => {
    if (!logged) navigate('/login');
    setSubscribers(assinantes);
    setProduto('global');
    // eslint-disable-next-line
  }, []);

  const editTenantLogo = async (e) => {
    e.preventDefault();
    try {
      await api.postFiles(end.tenants.tenant.update.logo, {
        files: files,
        tenant: status.tenant,
        tenant_codigo: status.tenant_codigo,
        logo_type: files.type,
      });

      setFiles(null);
      document.getElementById('logo_upld').value = '';
    } catch (error) {
      console.error('Erro ao fazer upload:', error);
    }
  };

  const editTenantTimbrado = async (e) => {
    e.preventDefault();
    try {
      await api.postFiles(end.tenants.tenant.update.timbrado, {
        files: files,
        tenant: status.tenant,
        tenant_codigo: status.tenant_codigo,
        timbrado_type: files.type,
      });

      setFiles(null);
      document.getElementById('timbrado_upld').value = '';
    } catch (error) {
      console.error('Erro ao fazer upload:', error);
    }
  };

  const editTenant = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('name', status.name);
      formData.append('email', status.email);
      formData.append('telefone', status.telefone);
      formData.append('endereco', status.endereco);
      formData.append('cidade_uf', status.cidadeUF);
      formData.append('tenant', status.tenant);
      formData.append('tenant_codigo', status.tenant_codigo);

      await api.post(end.tenants.tenant.update.all, formData);
      const assinantesResponse = await api.post(
        end.views.tenants_assinantes_users,
      );
      sessionStorage.setItem(
        '@App:assinantes',
        JSON.stringify(assinantesResponse.data),
      );
      const { assinantes } = store();
      setSubscribers(assinantes);
    } catch (error) {
      console.error('Erro ao fazer upload:', error);
    }
  };

  if (loading) return <LoadingFull />;

  return (
    <ContainerBox
      navbar={<NavBar logged={logged} navreturn />}
      main={
        <main className='container my-3 gocol align-items-start'>
          <p className='my-2'>Olá, {user}!</p>
          <h4 className=' mt-3'>{tenant}</h4>

          <h6 className='mt-4'>Dados da Conta</h6>
          <div className='my-3 d-flex flex-column'>
            <p className='my-0 d-flex flex-row '>
              <span className='wp100 fs10 textContrastLow'>Nome</span>
              <span>{TENANT_NAME}</span>
            </p>
            <p className='my-0 d-flex flex-row '>
              <span className='wp100 fs10 textContrastLow'>E-mail</span>
              <span>{TENANT_EMAIL}</span>
            </p>
            <p className='my-0 d-flex flex-row '>
              <span className='wp100 fs10 textContrastLow'>Endereço</span>
              <span>{TENANT_ENDERECO}</span>
            </p>
            <p className='my-0 d-flex flex-row '>
              <span className='wp100 fs10 textContrastLow'>Cidade-UF</span>
              <span>{TENANT_CIDADE_UF}</span>
            </p>
            <p className='my-0 d-flex flex-row '>
              <span className='wp100 fs10 textContrastLow'>Telefone</span>
              <span>{TENANT_TELEFONE}</span>
            </p>
          </div>

          <div>
            <span>Logotipo</span>
            <BlobViewer
              blobCode={TENANT_CODIGO}
              blobType='image'
              blobIcon={fileIcon('image')}
              blobTable='TENANTS'
              tenantCode={TENANT_CODIGO}
              tenant={tenant}
              token={store().token}
              clsnm='p-3 bgMenu rounded'
            />
          </div>

          <p className='mt-3 fw-500 ffSoft textContrastColor'>
            Editar dados da conta
            <br />
            <span className='fs10 textContrastLow mt-0'>
              Os dados de e-mail, endereço e telefone serão utilizados para
              todos os documentos
            </span>
          </p>

          <Form
            className='form-base'
            data-bs-theme={theme}
          >
            <div className='d-flex flex-row align-items-center mb-0'>
              <Form.Label className='me-2 fs12 my-0'>Logotipo</Form.Label>
              <Form.Control
                type='file'
                size='sm'
                id='logo_upld'
                onChange={(e) => setFiles(e.target.files[0])}
                accept='.png, .jpg, .jpeg'
              />
              <Button
                variant='primary'
                className='ms-1'
                size='sm'
                type='submit'
                onClick={(event) => editTenantLogo(event)}
              >
                Salvar
              </Button>
            </div>
            <p className='mt-0 mb-3 fs10 textContrastLow'>
              Utilize um arquivo PNG, na proporção 1022px x 171px
            </p>
            <div className='d-flex flex-row align-items-center mb-0'>
              <Form.Label className='me-2 fs12 my-0'>Timbrado</Form.Label>
              <Form.Control
                type='file'
                size='sm'
                id='timbrado_upld'
                onChange={(e) => setFiles(e.target.files[0])}
                accept='.doc, .docx'
              />
              <Button
                variant='primary'
                className='ms-1'
                size='sm'
                type='submit'
                onClick={(event) => editTenantTimbrado(event)}
              >
                Salvar
              </Button>
            </div>
            <p className='mt-0 mb-3 fs10 textContrastLow'>
              Importante: o seu timbrado será utilizado como base, e deve conter
              um único parágrafo, com o seguinte texto:{' '}
              <span className='textContrast fw600'>{`{{content}}`}</span>
              <br />
              Coloque o texto incluindo as chaves, e o sistema irá substituir
              pelo conteúdo do documento
            </p>

            <div className='d-flex flex-column justify-content-between'>
              <FloatingLabel
                label='Nome'
                className='my-1 fs10'
              >
                <Form.Control
                  type='text'
                  id='nome'
                  size='sm'
                  value={status.name}
                  onChange={(e) =>
                    setStatus({ ...status, name: e.target.value })
                  }
                />
              </FloatingLabel>

              <div className='d-flex flex-row justify-content-between'>
                <FloatingLabel
                  label='E-mail'
                  className=' my-1 me-1 col-5 fs10'
                >
                  <Form.Control
                    type='email'
                    size='sm'
                    id='email'
                    placeholder='email'
                    onChange={(e) =>
                      setStatus({ ...status, email: e.target.value })
                    }
                    value={status.email}
                  />
                </FloatingLabel>
                <FloatingLabel
                  label='Telefone'
                  className='my-1 col-3 me-1 fs10'
                >
                  <Form.Control
                    type='text'
                    id='telefone'
                    placeholder='telefone'
                    size='sm'
                    onChange={(e) =>
                      setStatus({ ...status, telefone: e.target.value })
                    }
                    value={status.telefone}
                  />
                </FloatingLabel>
                <FloatingLabel
                  label='CEP'
                  className='my-1 fs10 col-2'
                >
                  <Form.Control
                    type='text'
                    className=''
                    as={IMaskInput}
                    mask='00000-000'
                    size='sm'
                    placeholder='CEP'
                    id='cep'
                    value={status.cep}
                    onChange={(e) =>
                      setStatus({ ...status, cep: e.target.value })
                    }
                    onBlur={(e) => {
                      if (e.target.value.length === 9) {
                        getCEP(e);
                      }
                    }}
                  />
                </FloatingLabel>
                <FloatingLabel
                  label='nº'
                  className='my-1 fs10 col-1'
                >
                  <Form.Control
                    type='text'
                    size='sm'
                    placeholder='nº'
                    id='numero'
                    value={status.numero}
                    onChange={(e) => {
                      editEndereco(e.target.value);
                    }}
                  />
                </FloatingLabel>
              </div>
              <FloatingLabel
                label='Endereço'
                className='my-1 w100 me-1'
              >
                <Form.Control
                  type='text'
                  placeholder='Endereço'
                  size='sm'
                  id='endereco'
                  className='w100'
                  value={status.endereco}
                  onChange={(e) =>
                    setStatus({ ...status, endereco: e.target.value })
                  }
                />
              </FloatingLabel>

              <FloatingLabel
                label='Cidade-UF'
                className='my-1 w100 me-1'
              >
                <Form.Control
                  type='text'
                  placeholder='Cidade-UF'
                  id='cidadeuf'
                  size='sm'
                  className='w100'
                  value={status.cidadeUF}
                  onChange={(e) =>
                    setStatus({ ...status, cidadeUF: e.target.value })
                  }
                />
              </FloatingLabel>

              <Button
                variant='primary'
                type='submit'
                size='sm'
                onClick={(event) => editTenant(event)}
              >
                Salvar
              </Button>
            </div>
          </Form>

          <div className='d-flex flex-column mt-3 mb-3'>
            <p className='my-3 fw-500 ffSoft textContrastColor'>
              Configuração de Armazenamento
            </p>

            <StorageServices tenant={tenant} />

            {/* <GoogleDrive tenant={tenant} /> */}
          </div>
        </main>
      }
    />
  );
}
