import API from './requests';
import {VIEWS} from './endpoints';

export async function assinantes() {
  const token = sessionStorage.getItem('@App:token');
  const response = await API(VIEWS.tenants_assinantes_users, {
    token,
  });
  return response;
}
