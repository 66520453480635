import { useContext, useEffect } from 'react';
import NavBar from '../components/NavBar';
import LoadingFull from '../components/LoadingFull';
import {GlobalContext} from '../context/Context';
import '../assets/css/home.css';

import CONF from '../config';
import PackageContext from '../packages/wookie/context/PackageContext';

export default function Home() {
  const { loading, setProduto} = useContext(GlobalContext);
  const { theme } = useContext(PackageContext);
  const { images } = CONF;
  // const [logged, setLogged] = useState(false);

  useEffect(() => {
    setProduto('global');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <LoadingFull />;

  // if (logged) navigate('/portal');

  return (
    <>
      <NavBar />
      <main className='container'>
        <div className='d-flex flex-row'>
          <section className='main-container-render'>
            <div className='main-home'>
              <img
                src={images[theme].logo}
                alt=''
                className='logo-main'
              />
              <p className='fw400 f12 fc-regular py-3'>
                Soluções inteligentes em software
              </p>
            </div>

            <section
              id='assinaturas'
              className='pricing'
            >
              <div className='container'>
                <p className='h3 text-center py-3 fc-blue fw600'>Assine já</p>

                <div className='row gy-4'>
                  <div className='col-lg-3 col-md-6'>
                    <div className='box hpMin300 gocol justify-content-center align-items-center'>
                      <h3>Juris</h3>
                      <div className='price'>
                        <sup>R$</sup>79<span> / mês</span>
                      </div>
                      <img
                        src={images[theme].juris}
                        className='logo-subscription'
                        alt=''
                      />
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6'>
                    <div className='box hpMin300 gocol justify-content-center align-items-center'>
                      <h3>Assinador de Documentos</h3>
                      <div className='price'>
                        <sup>R$</sup>29<span> / mês</span>
                      </div>
                      <img
                        src={images[theme].signer}
                        className='logo-subscription'
                        alt=''
                      />
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6'>
                    <div className='box hpMin300 gocol justify-content-center align-items-center'>
                      <h3>Habitacional</h3>
                      <div className='price'>
                        <sup>R$</sup>79<span> / mês</span>
                      </div>
                      <img
                        src={images[theme].habita}
                        className='logo-subscription'
                        alt=''
                      />
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6'>
                    <div className='box hpMin300 gocol justify-content-center align-items-center'>
                      <h3>Calculadora</h3>
                      <div className='price'>
                        <sup>R$</sup>9<span> / mês</span>
                      </div>
                      <img
                        src={images[theme].calc}
                        className='logo-subscription'
                        alt=''
                      />
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6'>
                    <div className='box hpMin300 gocol justify-content-center align-items-center'>
                      <h3>Clinic</h3>
                      <div className='price'>
                        <sup>R$</sup>59<span> / mês</span>
                      </div>
                      <img
                        src={images[theme].clinic}
                        className='logo-subscription'
                        alt=''
                      />
                    </div>
                  </div>

                  <div className='col-lg-3 col-md-6'>
                    <div className='box hpMin300 gocol justify-content-center align-items-center'>
                      <h3>Gestão de Construção</h3>
                      <div className='price'>
                        <sup>R$</sup>49<span> / mês</span>
                      </div>
                      <img
                        src={images[theme].arch}
                        className='logo-subscription'
                        alt=''
                      />
                    </div>
                  </div>

                  <div className='col-lg-3 col-md-6'>
                    <div className='box hpMin300 gocol justify-content-center align-items-center'>
                      <h3>Gestão Empresarial</h3>
                      <div className='price'>
                        <sup>R$</sup>59<span> / mês</span>
                      </div>
                      <img
                        src={images[theme].manage}
                        className='logo-subscription'
                        alt=''
                      />
                    </div>
                  </div>

                  <div className='col-lg-3 col-md-6'>
                    <div className='box hpMin300 gocol justify-content-center align-items-center'>
                      <h3>Acesso básico</h3>
                      <div className='price'>
                        <sup>R$</sup>0<span> / mês</span>
                      </div>
                      <img
                        src={images[theme].logo}
                        className='logo-subscription'
                        alt=''
                      />
                    </div>
                  </div>

                  <p className='py-2 text-center'>
                    Para assinar qualquer serviço, faça login e acesse a área de
                    usuário
                  </p>
                </div>
              </div>
            </section>
          </section>
        </div>
      </main>
    </>
  );
}
