import { useEffect, useState } from 'react';

import './packages/wookie/assets/css';

import './assets/css/_root_.css';
// import './assets/css/verified.css';

// import 'bootstrap/dist/css/bootstrap.min.css';
// import './assets/css/app/root.css';
// import './assets/css/app/font.css';
// import './assets/css/app/base.css';
// import './assets/css/app/color.css';
// import './assets/css/app/svg.css';
// import './assets/css/style.css';
import { CookiesProvider } from 'react-cookie';
import GlobalProvider from './context/GlobalProvider';
import PackageProvider from './packages/wookie/context/PackageProvider';
import LoadingFull from './components/LoadingFull';
// import CONF from './config';

import AppRoutes from './routes/AppRoutes';
// import SignerRoutes from './routes/SignerRoutes';
// import PadRoutes from './routes/PadRoutes';
// import AdminRoutes from './routes/AdminRoutes';

export default function App() {
  // const [svc, setSvc] = useState('bewook');
  const [Loading, setLoading] = useState(true);

  // const selectRoutes = () => {
  //   switch (svc) {
  //     case 'www':
  //       return <AppRoutes />;
  //     case 'signer':
  //       return <SignerRoutes />;
  //     case 'pad':
  //       return <PadRoutes />;
  //     case 'dash':
  //       return <AdminRoutes />;
  //     default:
  //       return <AppRoutes />;
  //   }
  // };

  useEffect(() => {
    setLoading(true);
    // let serviceName = window.location.hostname.replace('.bewook.com', '');

    // if (window.location.host === 'localhost:8974') serviceName = 'www';
    // if (window.location.host === 'localhost:8975') serviceName = 'signer';
    // if (window.location.host === 'localhost:8976') serviceName = 'pad';
    // if (window.location.host === 'localhost:8977') serviceName = 'dash';
    // setSvc(serviceName);
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light';
    const savedTheme = localStorage.getItem('theme') || mediaQuery;
    if (savedTheme) {
      document.documentElement.className = savedTheme;
    }
    setLoading(false);
  }, []);

  if (Loading) {
    return <LoadingFull />;
  }

  return (
    <CookiesProvider>
      <PackageProvider>
        <GlobalProvider><AppRoutes /></GlobalProvider>
      </PackageProvider>
    </CookiesProvider>
  );
}
