import React, { useContext } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Timbrado from './settings/Timbrado';
import Indices from './settings/Indices';
import Feriados from './settings/Feriados';
import PackageContext from '../../packages/wookie/context/PackageContext';

export default function Settings() {
  const { theme } = useContext(PackageContext);
  return (
    <div className='ms-3 wh-tabs'>
      <h4 className='softxt'>Configurações</h4>

      <p className='mt-3'>Cadastro</p>

      <Tabs
        defaultActiveKey='advs'
        id='tab-modelos'
        className='my-3'
        data-bs-theme={theme}

      >
        {/* <Tab
          eventKey='advs'
          title='Advogados'
        >
          <Advogados theme={theme} />
        </Tab> */}

        <Tab
          eventKey='proc'
          title='Procurações'
        >
          Procurações 
        </Tab>
        <Tab
          eventKey='contratos'
          title='Contratos'
        >
          Contratos
        </Tab>
        <Tab
          eventKey='docs'
          title='Documentos'
        >
          Documentos
        </Tab>
        <Tab
          eventKey='guide'
          title='Orientações'
        >
          Orientações
        </Tab>
        <Tab
          eventKey='timbrado'
          title='Timbrado'
        >
          <Timbrado />
        </Tab>
      </Tabs>
      <br />

      <Tabs
        defaultActiveKey='indices'
        id='tab-config'
        className='my-3'
        data-bs-theme={theme}
      >
        <Tab
          eventKey='indices'
          title='Índices'
        >
          <Indices theme={theme} />
        </Tab>
        <Tab
          eventKey='foruns'
          title='Fóruns'
        >
          Fóruns
        </Tab>
        <Tab
          eventKey='feriados'
          title='Feriados'
        >
          <Feriados  theme={theme}/>
        </Tab>
        <Tab
          eventKey='contas'
          title='Contas'
        >
          Contas Bancárias
        </Tab>
        <Tab
          eventKey='centros'
          title='Centros de Custo'
        >
          Centros de Custo
        </Tab>
        <Tab
          eventKey='classes'
          title='Classes de Lançamento'
        >
          Classes de Lançamento
        </Tab>
      </Tabs>

      {/* <Form className='form-base'>
        <div className='d-flex flex-row align-items-center'>
          <Form.Label className='me-2 f09 my-0'>Logotipo</Form.Label>
          <Form.Control
            type='file'
            size='sm'
            onChange={(e) => setFiles(e.target.files[0])}
            accept='.png, .jpg, .jpeg'
          />
        </div>
        <div className='d-flex flex-column justify-content-between'>
          <FloatingLabel
            label='Nome'
            className='my-1'
          >
            <Form.Control
              type='text'
              id='nome'
              value={status.nome}
              onChange={(e) => setStatus({ ...status, nome: e.target.value })}
            />
          </FloatingLabel>

          <div className='d-flex flex-row'>
            <FloatingLabel
              label='E-mail'
              className=' my-1 me-1 wp-300 inputme1'
            >
              <Form.Control
                type='email'
                id='email'
                placeholder='email'
                onChange={(e) =>
                  setStatus({ ...status, email: e.target.value })
                }
                value={status.email}
              />
            </FloatingLabel>
            <FloatingLabel
              label='Telefone'
              className='my-1 wp-200 me-1 inputme1'
            >
              <Form.Control
                type='text'
                id='telefone'
                placeholder='telefone'
                onChange={(e) =>
                  setStatus({ ...status, telefone: e.target.value })
                }
                value={status.telefone}
              />
            </FloatingLabel>
            <FloatingLabel
              label='CEP'
              className='my-1'
            >
              <Form.Control
                type='text'
                className='wp-120'
                as={IMaskInput}
                mask='00000-000'
                placeholder='CEP'
                id='cep'
                value={status.cep}
                onChange={(e) => setStatus({ ...status, cep: e.target.value })}
                onBlur={(e) => {
                  if (e.target.value.length === 9) {
                    getCEP(e);
                  }
                }}
              />
            </FloatingLabel>
          </div>
          <FloatingLabel
            label='Endereço'
            className='my-1 w100 me-1'
          >
            <Form.Control
              type='text'
              placeholder='Endereço'
              id='endereco'
              className='w100'
              value={status.endereco}
              onChange={(e) =>
                setStatus({ ...status, endereco: e.target.value })
              }
            />
          </FloatingLabel>

          <Button
            variant='primary'
            type='submit'
          >
            Salvar
          </Button>
        </div>
      </Form> */}
    </div>
  );
}
