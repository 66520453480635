import React, { useContext, useEffect, useState } from 'react';
import '../assets/css/components/navbar.css';
import { GlobalContext } from '../context/Context';
import Dropdown from 'react-bootstrap/Dropdown';
import { IconsUI, ui } from '../packages/wookie/icons';

import PackageContext from '../packages/wookie/context/PackageContext';
import CONF from '../config';

import ThemeSwitch from '../packages/wookie/misc/ThemeSwitch';

export default function NavBar({
  navreturn = false,
  showLogo = true,
  unsetBg = false,
  tenantMenu = false,
}) {
  const { images } = CONF;
  const {
    navigate,
    logout,
    checkLogin,
    produto,
    setProduto,
    tenant,
    setTenant,
    store,
  } = useContext(GlobalContext);
  const [logged, setLogged] = useState(false);
  const { theme } = useContext(PackageContext);

  const { tenantsUsers } = store();

  useEffect(() => {
    const islogged = checkLogin();
    setLogged(islogged);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const tenantsMenu = () => {
    if (!tenantsUsers) return null;
    if (!tenantMenu) return null;

    const spans = tenantsUsers.map((t, index) => {
      return (
        <span
          key={index}
          className='sp-link px-2 py-1 nowrap'
          onClick={() => {
            setTenant(t);
          }}
        >
          {t.TENANT_NAME}
        </span>
      );
    });

    return (
      <Dropdown data-bs-theme={theme}>
        <Dropdown.Toggle
          variant=''
          id='dropdown-tenants'
          aria-label='dropdown-tenants'
          className={`p-1 fs10 ${tenant ? 'cYellow fw600 ffSoft' : 'cRed'}`}
        >
          {tenant ? tenant.TENANT_NAME : 'Selecione uma conta'}
        </Dropdown.Toggle>
        <Dropdown.Menu>{spans}</Dropdown.Menu>
      </Dropdown>
    );
  };

  const menu = (isLogged) => {
    const items = [
      { label: 'Portal', link: '/portal', logged: true, all: false, clsnm: '' },
      {
        label: 'Minha Conta',
        link: '/user',
        logged: true,
        all: false,
        clsnm: '',
      },
      {
        label: 'Sair',
        link: '/login',
        logged: true,
        all: false,
        clsnm: 'cRed fw500',
      },
      { label: 'hr', logged: true, all: false, clsnm: ' ' },
      {
        label: 'Privacidade',
        link: '/privacidade',
        logged: false,
        all: true,
        clsnm: ' ',
      },
      {
        label: 'Termos e Condições',
        link: '/termos',
        logged: false,
        all: true,
        clsnm: ' ',
      },
    ];

    const menuLogged = items.filter((item) => item.logged || item.all);
    const menuNotLogged = items.filter((item) => !item.logged || item.all);

    const menuItems = (isLogged ? menuLogged : menuNotLogged).map(
      (item, index) => {
        if (item.label === 'hr') {
          return <hr key={index} />;
        }
        return (
          <span
            key={index}
            className={`sp-link px-2 py-1 ${item.clsnm} nowrap`}
            onClick={() => {
              if (item.label === 'Sair') {
                handleLogout();
              } else {
                setProduto('global');
                navigate(item.link);
              }
            }}
          >
            {item.label}
          </span>
        );
      },
    );

    return (
      <Dropdown data-bs-theme={theme}>
        <Dropdown.Toggle
          variant=''
          id='dropdown-items'
          aria-label='dropdown-items'
          className='p-1'
        >
          <IconsUI
            info={ui.misc.config}
            clsnm='hovGreen mousePointerHover'
          />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {menuItems}
          <hr />

          <ThemeSwitch drop={false} />
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const menuApps = (isLogged) => {
    const apps = [
      { label: 'Juris', link: '/juris', clsnm: '', logo: 'juris' },
      { label: 'Arch', link: '/arch', clsnm: '', logo: 'arch' },
      { label: 'Calc', link: '/calc', clsnm: '', logo: 'calc' },
      { label: 'Clinic', link: '/clinic', clsnm: '', logo: 'clinic' },
      { label: 'Habita', link: '/habita', clsnm: '', logo: 'habita' },
      { label: 'Manage', link: '/manage', clsnm: '', logo: 'manage' },
      { label: 'Pad', link: '/pad', clsnm: '', logo: 'pad' },
      { label: 'Signer', link: '/signer', clsnm: '', logo: 'signer' },
    ];

    const menuItems = apps.map((item, index) => {
      return (
        <span
          key={index}
          className={`sp-link px-2 py-1 ${item.clsnm} nowrap`}
          onClick={() => {
            setProduto(item.logo);
            navigate(item.link);
          }}
        >
          <img
            src={images[theme][item.logo]}
            alt={item.label}
            title={item.label}
            aria-label={item.label}
            className='wp125 px-2 py-3'
          />
        </span>
      );
    });

    if (!isLogged) return null;

    return (
      <Dropdown
        data-bs-theme={theme}
        className='p-0'
      >
        <Dropdown.Toggle
          variant=''
          id='dropdown-apps'
          aria-label='dropdown-apps'
          className='p-1'
        >
          <IconsUI
            info={ui.misc.products}
            clsnm='hovGreen mousePointerHover'
          />
        </Dropdown.Toggle>
        <Dropdown.Menu>{menuItems}</Dropdown.Menu>
      </Dropdown>
    );
  };

  if (logged) {
    return (
      <div className={`navbar-top ${unsetBg ? 'unsetBg' : ''}`}>
        <div className='container d-flex flex-row align-content-center align-items-center justify-content-between py-3 px-1'>
          {showLogo ? (
            <img
              src={
                produto === 'global'
                  ? images[theme].logo
                  : images[theme][produto]
              }
              className='w50 wpMin125 wpMax150 p-2'
              alt={`logo_${produto}`}
              aria-label={`logo_${produto}`}
              onClick={() => {
                const destination = produto === 'global' ? '/' : produto;
                navigate(destination);
              }}
            />
          ) : (
            <span className='p-2'></span>
          )}

          <div className='d-flex flex-row justify-content-end align-items-center'>
            {produto === 'global' ? null : (
              <img
                src={images.w}
                alt='home'
                title='Home'
                className='wp20 mousePointerHover me-1'
                onClick={() => {
                  setProduto('global');
                  navigate('/');
                }}
              />
            )}
            {menuApps(logged)}
            {menu(logged)}
            {tenantsMenu()}
            {navreturn ? (
              <IconsUI
                info={ui.action.back}
                clsnm={` ms-2 hovGreen mousePointerHover`}
                click={() => navigate(-1)}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={`navbar-top ${unsetBg ? 'unsetBg' : ''}`}>
      <div className='container d-flex flex-row align-content-center  align-content-center justify-content-between py-3 px-1'>
        {showLogo ? (
          <img
            src={
              produto === 'global' ? images[theme].logo : images[theme][produto]
            }
            className='w50 wpMin125 wpMax150 p-2'
            alt={`logo_${produto}`}
            aria-label={`logo_${produto}`}
            onClick={() => {
              const destination = produto === 'global' ? '/' : produto;
              navigate(destination);
            }}
          />
        ) : (
          <span className='p-2'></span>
        )}

        <div className='d-flex flex-row justify-content-end align-items-center'>
          <span
            className='sp-link '
            onClick={() => navigate('/login')}
          >
            Login
          </span>
          {menuApps(logged)}
          {menu(logged)}
        </div>
      </div>
    </div>
  );
}
