import React, { useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
import { IMaskInput } from 'react-imask';
import { formatCapitalizeFirst, formatCEP } from '../../assets/js/helpers';
import PackageContext from '../../packages/wookie/context/PackageContext';
import { GlobalContext } from '../../context/Context';

import Loading from '../Loading';

import { consultarCEP, consultarCNPJ } from '../../services/external';
import { IconsUI, ui } from '../../packages/wookie/icons';

export default function Cadastros() {
  const { theme } = useContext(PackageContext);
  const { tenant } = useContext(GlobalContext);

  // const [formData, setFormData] = useState({
  //   TENANT_CODIGO: "",
  //   TIPO: "",
  //   ORIGEM: "",
  //   NOME: "",
  //   ENDERECO: "",
  //   TELEFONE: "",
  //   WHATSAPP: "",
  //   EMAIL: "",
  //   RG: "",
  //   CPF: "",
  //   NASCIMENTO: "",
  //   NATURALIDADE: "",
  //   NACIONALIDADE: "",
  //   PROFISSAO: "",
  //   ESTADO_CIVIL: "",
  //   NIS: "",
  //   SENHA_INSS: "",
  //   CARACTERISTICAS: "",
  //   CNPJ: "",
  //   ESTADUAL: "",
  //   MUNICIPAL: "",
  //   CNAE_PRINCIPAL: "",
  //   GENERO: "",
  //   QUALIFICACAO: "",
  //   CLIENTE: 1,
  //   FORNECEDOR: 0,
  //   ACTIVE: 1,
  //   INFORMACOES: "",
  // });

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({ ...formData, [name]: value });
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log(formData); // Substitua por sua lógica de envio
  // };

  // const consultarCNPJ = require('consultar-cnpj');

  const initialState = {
    tenant: tenant,
    tipoPessoa: '',
    nome: '',
    endereco: '',
    numero: '',
    cep: '',
    documento: '',
    documentotipo: '',
    whatsapp: '',
    email: '',

    nacionalidade: '',
    naturalidade: '',
    profissao: '',
    nascimento: '',
    estadocivil: '',
    sexo: '',
    caracteristicaspessoais: '',
    nis: '',
    senhainss: '',
    rg: '',
    cpf: '',

    cnpj: '',
    estadual: '',
    municipal: '',
    representante: '',
    cnaeprincipal: '',

    informacoes: '',
  };

  const [cliente, setCliente] = useState(initialState);
  const [loading, setLoading] = useState(false);

  async function getCEP() {

    const cepValue = document.getElementById('cep').value;
    if (!cepValue) {
      alert('CEP não informado');
      return;
    }

    if(cepValue !== cliente.cep) {
      setCliente({ ...cliente, cep: cepValue });
    }

    const data = await consultarCEP(cepValue);
    if (data.erro) {
      alert('CEP não encontrado');
      return;
    }
    setCliente({
      ...cliente,
      endereco: `${data.logradouro}, n° ${data.numero}, bairro ${data.bairro}, CEP ${data.cep}, ${data.localidade}-${data.uf}`,
    });
  }

  async function getCNPJ() {
    setLoading(true);

    const docValue = document.getElementById('documento').value;
    if (cliente.documento !== docValue) {
      setCliente({ ...cliente, documento: docValue });
    }
    if (!docValue) {
      alert('CNPJ não informado');
      return;
    }

    const res = await consultarCNPJ(docValue);
    if (res) {
      console.log(res);
      if (res.status > 399) {
        alert(res.detalhes);
        return;
      }

      setCliente({
        ...cliente,
        nome: res.razao_social,
        endereco: `${formatCapitalizeFirst(
          res.estabelecimento.tipo_logradouro,
        )} ${formatCapitalizeFirst(res.estabelecimento.logradouro)}, n° ${
          res.estabelecimento.numero
        }, bairro ${formatCapitalizeFirst(
          res.estabelecimento.bairro,
        )}, CEP ${formatCEP(res.estabelecimento.cep)}, ${formatCapitalizeFirst(
          res.estabelecimento.cidade.nome,
        )}-${res.estabelecimento.estado.sigla}`,
        cep: formatCEP(res.estabelecimento.cep),
        numero: res.estabelecimento.numero,
        email: res.estabelecimento.email,
        whatsapp: `${res.estabelecimento.ddd1} ${res.estabelecimento.telefone1}`,
        informacoes: `${res.estabelecimento.ddd1} ${res.estabelecimento.telefone1}`,
        representante: res.socios[0].nome,
        cnpj: res.cnpj,
        estadual:
          res.estabelecimento.inscricoes_estaduais.length > 0 &&
          res.estabelecimento.inscricoes_estaduais[0].inscricao_estadual,
      });
    }

    setLoading(false);
  }

  return (
    <div className='ms-3'>
      <h4 className='ffSoft m-1'>Cadastros</h4>
      <div className='w100'>
        <Form
          className='w95 wpMax800 fs10'
          data-bs-theme={theme}
        >
          <FloatingLabel
            className='m-1'
            label='Tipo de Cliente'
          >
            <Form.Select
              aria-label='TipoPessoa'
              size='sm'
              className=''
              onChange={(e) => {
                if (e.target.value === 'PF') {
                  setCliente({
                    ...cliente,
                    tipoPessoa: e.target.value,
                    documentotipo: 'CPF',
                  });
                } else {
                  setCliente({
                    ...cliente,
                    tipoPessoa: e.target.value,
                    documentotipo: 'CNPJ',
                  });
                }
              }}
            >
              <option></option>
              <option value='PF'>Pessoa Física</option>
              <option value='PJ'>Pessoa Jurídica</option>
            </Form.Select>
          </FloatingLabel>
          <div className='m-1 gorow'>
            <Form.Check
              type='checkbox'
              id='cliente'
              label='Cliente'
              onChange={(e) =>
                setCliente({ ...cliente, cliente: e.target.checked })
              }
            />

            <Form.Check
              type='checkbox'
              id='fornecedor'
              label='Fornecedor'
              className='ms-3'
              onChange={(e) =>
                setCliente({ ...cliente, fornecedor: e.target.checked })
              }
            />
          </div>

          <div
            className={cliente.tipoPessoa === '' ? 'd-none' : 'd-block'}
            id='selectTipoPessoa'
          >
            <InputGroup className='mb-3'>
              <FloatingLabel
                label={cliente.tipoPessoa === 'PF' ? 'CPF' : 'CNPJ'}
                className='m-1'
              >
                <Form.Control
                  as={IMaskInput}
                  type='text'
                  id='documento'
                  placeholder={cliente.tipoPessoa === 'PF' ? 'CPF' : 'CNPJ'}
                  mask={
                    cliente.tipoPessoa === 'PF'
                      ? '000.000.000-00'
                      : '**.***.***/****-**'
                  }
                  onChange={(e) => {
                    setCliente({ ...cliente, documento: e.target.value });
                  }}
                  // onBlur={(e) => {
                  //   if (
                  //     cliente.tipoPessoa === 'PJ' &&
                  //     e.target.value.length > 17
                  //   ) {
                  //     getCNPJ(e);
                  //   }
                  // }}
                />
              </FloatingLabel>
              {cliente.tipoPessoa === 'PF' ? null : (
                <Button
                  variant='outline-secondary'
                  className='my-1 me-1'
                  id='button-cnpj'
                  size='sm'
                >
                  <IconsUI
                    info={ui.action.magnifier}
                    clsnm='svg18'
                    click={() => {
                      getCNPJ();
                    }}
                  />
                </Button>
              )}
            </InputGroup>

            {loading ? (
              <Loading class_op='w100 gorow justify-content-center' />
            ) : (
              <>
                <FloatingLabel
                  label={cliente.tipoPessoa === 'PF' ? 'Nome' : 'Razão Social'}
                  className='m-1'
                >
                  <Form.Control
                    type='text'
                    placeholder={cliente.tipoPessoa === 'PF' ? 'CPF' : 'CNPJ'}
                    id='nome'
                    value={cliente.nome}
                    onChange={(e) =>
                      setCliente({ ...cliente, nome: e.target.value })
                    }
                  />
                </FloatingLabel>

                <div className='d-flex flex-column'>
                  <span className='mx-1 mt-2 mb-0'>Endereço</span>

                  <div className='d-flex flex-row w100'>
                    <FloatingLabel
                      label='nº'
                      className='m-1 mt-0'
                    >
                      <Form.Control
                        type='text'
                        placeholder='nº, complemento'
                        id='numero'
                        className='wp50 fs12'
                        value={cliente.numero}
                        onChange={(e) =>
                          setCliente({ ...cliente, numero: e.target.value })
                        }
                      />
                    </FloatingLabel>

                    <InputGroup className='m-1 mt-0 wp175'>
                      <FloatingLabel label='CEP'>
                        <Form.Control
                          type='text'
                          className='wp90 fs12'
                          as={IMaskInput}
                          mask='00000-000'
                          placeholder='CEP'
                          id='cep'
                          value={cliente.cep}
                          onChange={(e) =>
                            setCliente({ ...cliente, cep: e.target.value })
                          }
                        />
                      </FloatingLabel>
                      <Button
                        variant='outline-secondary'
                        className='wp40'
                        id='button-cnpj'
                        size='sm'
                      >
                        <IconsUI
                          info={ui.action.magnifier}
                          clsnm='svg18'
                          click={() => {
                            getCEP();
                          }}
                        />
                      </Button>
                    </InputGroup>
                    <FloatingLabel
                      label='Endereço'
                      className='m-1 mt-0 w100'
                    >
                      <Form.Control
                        type='text'
                        placeholder='Endereço'
                        id='endereco'
                        className='w100 fs12'
                        value={cliente.endereco}
                        onChange={(e) =>
                          setCliente({ ...cliente, endereco: e.target.value })
                        }
                      />
                    </FloatingLabel>
                  </div>
                </div>

                <FloatingLabel
                  className='m-1'
                  label='E-mail'
                >
                  <Form.Control
                    type='email'
                    id='email'
                    placeholder='email'
                    onChange={(e) =>
                      setCliente({ ...cliente, email: e.target.value })
                    }
                    value={cliente.email}
                  />
                </FloatingLabel>
                <FloatingLabel
                  className='m-1'
                  label='Whatsapp'
                >
                  <Form.Control
                    type='text'
                    id='whatsapp'
                    placeholder='whatsapp'
                    as={IMaskInput}
                    mask='(00) 00000-0000'
                    onChange={(e) =>
                      setCliente({ ...cliente, whatsapp: e.target.value })
                    }
                    value={cliente.whatsapp}
                  />
                </FloatingLabel>
                {cliente.tipoPessoa === 'PJ' ? (
                  <FloatingLabel
                    label='Representante'
                    className='m-1'
                  >
                    <Form.Control
                      type='text'
                      placeholder='Representante'
                      id='representante'
                      value={cliente.representante}
                      onChange={(e) =>
                        setCliente({
                          ...cliente,
                          representante: e.target.value,
                        })
                      }
                    />
                  </FloatingLabel>
                ) : null}
              </>
            )}

            {cliente.tipoPessoa === 'PF' ? (
              // nacionalidade: '',
              // naturalidade: '',
              // profissao: '',
              // nascimento: '',
              // estadocivil: '',
              // sexo: '',
              // caracteristicaspessoais: '',
              // nis: '',
              // senhainss: '',
              // rg: '',
              <>
                <FloatingLabel
                  label='Nacionalidade'
                  className='m-1'
                >
                  <Form.Control
                    type='text'
                    placeholder='Nacionalidade'
                    id='endereco'
                    value={cliente.nacionalidade}
                    onChange={(e) =>
                      setCliente({ ...cliente, nacionalidade: e.target.value })
                    }
                  />
                </FloatingLabel>

                <FloatingLabel
                  label='Naturalidade'
                  className='m-1'
                >
                  <Form.Control
                    type='text'
                    placeholder='Naturalidade'
                    id='endereco'
                    value={cliente.naturalidade}
                    onChange={(e) =>
                      setCliente({ ...cliente, naturalidade: e.target.value })
                    }
                  />
                </FloatingLabel>

                <FloatingLabel
                  label='Profissão'
                  className='m-1'
                >
                  <Form.Control
                    type='text'
                    placeholder='Profissão'
                    id='endereco'
                    value={cliente.profissao}
                    onChange={(e) =>
                      setCliente({ ...cliente, profissao: e.target.value })
                    }
                  />
                </FloatingLabel>

                <FloatingLabel
                  label='Nascimento'
                  className='m-1'
                >
                  <Form.Control
                    type='date'
                    placeholder='Nascimento'
                    id='endereco'
                    value={cliente.nascimento}
                    onChange={(e) =>
                      setCliente({ ...cliente, nascimento: e.target.value })
                    }
                  />
                </FloatingLabel>

                <FloatingLabel
                  label='Gênero'
                  className='m-1'
                >
                  <Form.Select
                    aria-label='Gênero'
                    size='sm'
                    // className='m-1'
                    onChange={(e) =>
                      setCliente({ ...cliente, genero: e.target.value })
                    }
                  >
                    <option></option>
                    <option value='F'>Feminino</option>
                    <option value='M'>Masculino</option>
                  </Form.Select>
                </FloatingLabel>
                <FloatingLabel
                  label='Estado Civil'
                  className='m-1'
                >
                  <Form.Select
                    aria-label='Estado Civil'
                    size='sm'
                    // className='m-1'
                    onChange={(e) =>
                      setCliente({ ...cliente, estadocivil: e.target.value })
                    }
                  >
                    <option></option>
                    <option
                      value={cliente.genero === 'F' ? 'Solteira' : 'Solteiro'}
                    >
                      {cliente.genero === 'F' ? 'Solteira' : 'Solteiro'}
                    </option>
                    <option
                      value={cliente.genero === 'F' ? 'Casada' : 'Casado'}
                    >
                      {cliente.genero === 'F' ? 'Casada' : 'Casado'}
                    </option>
                    <option
                      value={
                        cliente.genero === 'F' ? 'Divorciada' : 'Divorciado'
                      }
                    >
                      {cliente.genero === 'F' ? 'Divorciada' : 'Divorciado'}
                    </option>
                    <option value={cliente.genero === 'F' ? 'Viúva' : 'Viúvo'}>
                      {cliente.genero === 'F' ? 'Viúva' : 'Viúvo'}
                    </option>
                    <option value='convivendo em união estável'>
                      convivendo em união estável
                    </option>
                  </Form.Select>
                </FloatingLabel>
              </>
            ) : null}

            <Button
              variant='primary'
              type='submit'
              size='sm'
              className='w100 mt-3'
            >
              Salvar
            </Button>
          </div>
        </Form>
      </div>

      <h4 className='ffSoft m-1'>Cadastros</h4>
      <div className='w100'>
        <div className='w95 wpMax800 fs10'
        > 

        <Button size='sm' className='w100'>Ver cadastrados</Button>
        </div>

        </div>

    </div>
  );
}
