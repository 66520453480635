import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { GlobalContext } from './Context';
import * as api from '../services/requests';
import { getCEP } from '../assets/js/helpers';
import { login as makeLogin, logout as makeLogout } from '../services/auth';
import { assinantes as getAssinantes } from '../services/views';
import {
  tenantsUsers as getTenantsUsers,
  tenantsServices as getTenantServices,
} from '../services/tenants';
import { services as getServices } from '../services/services';

export default function GlobalProvider({ children }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [produto, setProduto] = useState('global');
  const [tenant, setTenant] = useState(null);
  const [redirectPath, setRedirectPath] = useState('/');
  const [svc, setSvc] = useState('bewook');
  const [isAuthenticated, setIsAuthenticated] = useState(
    sessionStorage.getItem('@App:token'),
  );

  const [cookies, setCookie, removeCookie] = useCookies([
    'token',
    'email',
    'user',
    'cpf',
    'codigo',
    'telefone',
    'cep',
    'endnum',
    'address',
    // 'assinantes',
    // 'tenantsUsers',
  ]);

  const cookiesProps = {
    path: '/',
    maxAge: 60 * 60 * 24,
    // sameSite: 'Strict',
    // domain: 'www.bewook.com',
    // secure: true,
    // httpOnly: true,
    // domain: 'localhost',
    // secure: false,
    // httpOnly: false,
  };

  function store() {
    return {
      token: cookies.token,
      email: cookies.email,
      user: cookies.user,
      cpf: cookies.cpf,
      codigo: cookies.codigo,
      telefone: cookies.telefone,
      cep: cookies.cep,
      endnum: cookies.endnum,
      address: cookies.address,
      assinantes: JSON.parse(sessionStorage.getItem('@App:assinantes')),
      tenantsUsers: JSON.parse(sessionStorage.getItem('@App:tenantsUsers')),
      tenantsServices: JSON.parse(
        sessionStorage.getItem('@App:tenantsServices'),
      ),
      appServices: JSON.parse(sessionStorage.getItem('@App:appServices')),
      appToken: sessionStorage.getItem('@App:token'),
    };
  }

  function storeUpdate(param, value) {
    if (cookies[param]) {
      setCookie(param, value, cookiesProps);
    }
    return {
      ...store(),
      [param]: value,
    };
  }

  function checkLogin() {
    const { token, email, user, appToken } = store();
    if (!token || !appToken || !email || !user) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    setIsAuthenticated(checkLogin());
    let serviceName = window.location.hostname.replace('.bewook.com', '');

    if (window.location.host === 'localhost:8974') serviceName = 'www';
    if (window.location.host === 'localhost:8975') serviceName = 'signer';
    if (window.location.host === 'localhost:8976') serviceName = 'pad';
    if (window.location.host === 'localhost:8977') serviceName = 'dash';

    setSvc(serviceName);

    const token = store().token;
    api.defaults.headers.Authorization = token;
    setLoading(false);
    // eslint-disable-next-line
  }, [cookies]);

  const removeAspas = (str) => {
    return str.replace(/['"]+/g, '');
  };

  async function login(userData) {
    const response = await makeLogin(userData);
    const { success } = response.data;

    if (!success) {
      setIsAuthenticated(true);
      return response.data;
    } else {
      const { email, token, cpf, user, codigo, telefone, cep, endnum } =
        response.data;
      sessionStorage.setItem('@App:token', removeAspas(JSON.stringify(token)));
      api.defaults.headers.Authorization = token;

      const assinantes = await getAssinantes();
      const tenantsUsers = await getTenantsUsers();
      const resTenantsServices = await getTenantServices();
      const tenantsServices = resTenantsServices.data;
      // const tenantsServices = resTenantsServices.data
      //   ? resTenantsServices.data.filter(
      //       (service) => service.SERVICE_CODIGO !== null,
      //     )
      //   : [];

      const appServices = await getServices();

      const end = cep ? await getCEP(cep) : null;
      setCookie('address', end, cookiesProps);

      // console.log({tenantsUsers, assinantes});

      // sessionStorage.setItem('@App:user', removeAspas(JSON.stringify(user)));
      // sessionStorage.setItem('@App:mail', removeAspas(JSON.stringify(email)));
      // sessionStorage.setItem('@App:cpf', removeAspas(JSON.stringify(cpf)));
      // sessionStorage.setItem(
      //   '@App:codigo',
      //   removeAspas(JSON.stringify(codigo)),
      // );
      // sessionStorage.setItem(
      //   '@App:telefone',
      //   removeAspas(JSON.stringify(telefone)),
      // );
      sessionStorage.setItem(
        '@App:assinantes',
        JSON.stringify(assinantes.data),
      );
      sessionStorage.setItem(
        '@App:tenantsUsers',
        JSON.stringify(tenantsUsers.data),
      );
      sessionStorage.setItem(
        '@App:tenantsServices',
        JSON.stringify(tenantsServices),
      );
      sessionStorage.setItem(
        '@App:appServices',
        JSON.stringify(appServices.data),
      );

      // console.log({assinantes: assinantes.data, tenantsUsers: tenantsUsers.data});
      // const tenantsUsersData = JSON.stringify(tenantsUsers.data);
      // const assinantesData = JSON.stringify(assinantes.data);

      setCookie('token', token, cookiesProps);
      setCookie('email', removeAspas(JSON.stringify(email)), cookiesProps);
      setCookie('user', removeAspas(JSON.stringify(user)), cookiesProps);
      setCookie('cpf', removeAspas(JSON.stringify(cpf)), cookiesProps);
      setCookie('codigo', removeAspas(JSON.stringify(codigo)), cookiesProps);
      setCookie(
        'telefone',
        removeAspas(JSON.stringify(telefone)),
        cookiesProps,
      );
      setCookie('cep', removeAspas(JSON.stringify(cep)), cookiesProps);
      setCookie('endnum', removeAspas(JSON.stringify(endnum)), cookiesProps);

      // setCookie('subscribers', assinantesData, cookiesProps);
      // setCookie('tenantsUsers', tenantsUsersData, cookiesProps);

      return response.data;
    }
  }

  async function logout(full = svc === 'dash') {
    await makeLogout({ full });
    removeCookie('token');
    removeCookie('email');
    removeCookie('user');
    removeCookie('cpf');
    removeCookie('codigo');
    removeCookie('telefone');
    removeCookie('cep');
    removeCookie('endnum');
    removeCookie('address');
    setIsAuthenticated(false);
    sessionStorage.clear();
    localStorage.clear();
    api.defaults.headers.Authorization = '';
    navigate('/');
  }

  const context = {
    login,
    logout,
    store,
    storeUpdate,
    cookies,
    setCookie,
    cookiesProps,
    checkLogin,
    navigate,
    loading,
    setLoading,
    produto,
    setProduto,
    redirectPath,
    setRedirectPath,
    isAuthenticated,
    setIsAuthenticated,
    svc,
    setSvc,
    tenant, setTenant,
  };

  return (
    <GlobalContext.Provider value={context}>{children}</GlobalContext.Provider>
  );
}
