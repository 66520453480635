import React, { useContext, useEffect } from 'react';
import NavBar from '../../components/NavBar';
import { GlobalContext } from '../../context/Context';
import ContainerBox from '../../packages/wookie/box/ContainerBox';
import PackageContext from '../../packages/wookie/context/PackageContext';

import '../../assets/css/app/pad.css';
import CONF from '../../config';
import { IC } from '../../packages/wookie/icons';
import PadProvider from '../../context/PadProvider';
import SideBar from '../../components/pad/SideBar';
import RenderSection from '../../components/pad/RenderSection';

export default function Pad() {
  const { theme } = useContext(PackageContext);
  const { setProduto, checkLogin, navigate, tenant } =
    useContext(GlobalContext);

  const logged = checkLogin();

  useEffect(() => {
    setProduto('pad');
  }, [setProduto]);

  const landing = () => {
    return (
      <ContainerBox
        navbar={
          <NavBar
            navreturn
            showLogo={false}
          />
        }
        main={
          <div className=' bgOverlayDarker m-5'>
            <div className='p-5'>
              <div className='d-flex flex-column justify-content-start p-3'>
                <img
                  src={CONF.images[theme].pad}
                  alt='bewook pad'
                  aria-label='bewook pad'
                  className='w80 wpMax300'
                />
                <p className='ffSoft'>Sua empresa na palma da sua mão</p>
              </div>

              <div className='d-flex flex-column justify-content-start my-3'>
                <section className='hero my-5 wMax90 mAuto'>
                  <h1>Facilite sua Gestão, Digitalize seu Escritório</h1>
                  <p>
                    Com a Plataforma Avançada Digital, você gerencia documentos,
                    equipe e tarefas de qualquer lugar. Toda a gestão
                    financeira, de contratos, clientes e fornecedores em um só
                    lugar.
                  </p>
                  <button
                    className='cta-button ffSoft'
                    onClick={() => navigate('/login')}
                  >
                    Comece Agora
                  </button>
                </section>

                <section className='features p-3'>
                  <div className='feature'>
                    <IC.FaRegFile className='svg26 mb-3' />
                    <h3>Gestão de Documentos</h3>
                    <p>
                      Organize, compartilhe e acesse todos os seus documentos em
                      um só lugar, integrando ao Google Drive
                    </p>
                  </div>
                  <div className='feature'>
                    <IC.LiaUserFriendsSolid className='svg26 mb-3' />

                    <h3>Colaboração em Equipe</h3>
                    <p>
                      Facilite o trabalho em equipe com ferramentas de
                      colaboração eficazes.
                    </p>
                  </div>
                  <div className='feature'>
                    <IC.FaRegCalendarAlt className='svg26 mb-3' />

                    <h3>Agendamentos Simples</h3>
                    <p>Gerencie compromissos e reuniões com praticidade.</p>
                  </div>
                </section>

                <section className='features'>
                  <div className='feature'>
                    <IC.PiCurrencyCircleDollarFill className='svg26 mb-3' />
                    <h3>Gestão Financeira</h3>
                    <p>
                      Controle suas finanças, contas a pagar e a receber, de
                      forma simples e eficiente.
                    </p>
                  </div>
                  <div className='feature'>
                    <IC.GoTasklist className='svg26 mb-3' />

                    <h3>Organização de tarefas e prazos</h3>
                    <p>
                      Organize tarefas e prazos de forma eficaz, evitando
                      esquecimentos.
                    </p>
                  </div>
                  <div className='feature'>
                    <IC.FaRegStar className='svg26 mb-3' />

                    <h3>E muito mais</h3>
                    <p>
                      Integre com outros módulos bewook, e eleve sua
                      produtividade a um novo patamar
                    </p>
                  </div>
                </section>

                <section className='hero my-3 wMax90 mAuto'>
                  <h3>Teste Grátis por 7 dias</h3>
                  <p className='px-3 mb-0'>
                    Experimente todas as funcionalidades do bewook Pad por 7
                    dias, sem compromisso.
                  </p>
                  <p className='fs10 mt-0'>
                    Algumas funcionalidades extras, como os cálculos ou o
                    assinador digital podem não estar disponíveis no período
                    gratuito
                  </p>
                  <button
                    className='cta-button ffSoft'
                    onClick={() => navigate('/login')}
                  >
                    Comece Agora
                  </button>
                </section>
              </div>
            </div>
          </div>
        }
        classMain='bgPad'
      />
    );
  };

  if (!logged) return landing();

  if (!tenant)
    return (
      <PadProvider>
        <ContainerBox
          classFull={`pad_${theme} vhMin100`}
          navbar={
            <NavBar
              navreturn
              unsetBg
              showLogo
              className='bgTransparent'
              tenantMenu
            />
          }
          main={
            <div className='p-2 d-flex flex-column justify-content-center'>
              <h5 className='faCenter'>Selecione uma conta no menu superior para continuar</h5>
            </div>
          }
          classMain='container'
        />
      </PadProvider>
    );

  return (
    <PadProvider>
      <ContainerBox
        classFull={`pad_${theme} vhMin100`}
        sidebar={<SideBar />}
        navbar={
          <NavBar
            navreturn
            unsetBg
            tenantMenu
            showLogo
            className='bgTransparent'
          />
        }
        main={
          <div className='p-2 d-flex flex-column justify-content-start'>
            <RenderSection />
          </div>
        }
        classMain='container'
      />
    </PadProvider>
  );
}
